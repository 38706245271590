import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { useTranslation } from '../hooks/useTranslation';
import { css } from '@emotion/react';
import { commonStyles, spacing } from '../config/styles';
import Container from '../components/Container';

const styles = {
  section: css`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  heading: css`
    ${commonStyles.h1}
    margin-bottom: ${spacing['120']};
  `,
};

const NotFoundPage = () => {
  const t = useTranslation();

  return (
    <Layout>
      <Seo title={t('not_found_page_title')} />
      <section css={styles.section}>
        <Container>
          <h1 css={styles.heading}>{t('not_found_text')}</h1>
        </Container>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
